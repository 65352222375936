import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form"
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Button, Form } from 'react-bootstrap'
import { message, Select } from 'antd'

import { getLocations } from '../../Locations/services'
import { storeAddress } from '../services'
import { handleError } from '../../../helpers'

export default function NewAddressModal({ visible, onClose, userId, reload }) {
	const { handleSubmit, register, errors } = useForm()
	const [locations, setLocations] = useState(null)
	const [departamento, setDepartamento] = useState(null)
	const [municipio, setMunicipio] = useState(null)
	const [localidad, setLocalidad] = useState(null)
	const [barrio, setBarrio] = useState(null)
	const [parentId, setParentId] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const type = !departamento ? 'departamento' : ( !municipio ? 'municipio' : ( !localidad ? 'localidad' : 'barrio' ) )

		!locations && getLocations({ 
			'filter[type]': type, 
			'filter[parent_location_id]': parentId,
			sort: 'name'
		})
			.then(res => {
				setLocations(res.data.data.map(location => ({
					label: location.name,
					value: location.id,
				})))
			})
			.catch(error => handleError(error))

	}, [ locations, departamento, municipio, localidad, parentId ])

	const onSubmit = values => {
		setLoading(true)
		storeAddress({
			...values, 
			departamento, 
			municipio, 
			localidad, 
			barrio,
			user_id: userId
		})
			.then(response => {
				message.success('Dirección almacenada correctamente')
				reload()
				onClose()
			})
			.catch(error => {
				message.error(`Algo salio mal. ${error.message}`)
				setLoading(false)
			})
	}

	const restartLocations = () => {
		setDepartamento(null)
		setMunicipio(null)
		setLocalidad(null)
		setBarrio(null)
		setLocations(null)
		setParentId(null)
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Agregar Nueva Dirección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
                     <Form.Label>Dirección <span className='text-danger'>*</span></Form.Label>
                     <Form.Control
                        name="address"
                        ref={register({ required: true })}
                     />
                     {errors.address && <Form.Text className="text-warning">Este campo es requerido</Form.Text>}
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>Especificaciones de Dirección <span className='text-danger'>*</span></Form.Label>
                     <Form.Control
                        name="details"
                        ref={register({ required: true })}
                     />
                     {errors.details && <Form.Text className="text-warning">Este campo es requerido</Form.Text>}
                  </Form.Group>
						<Form.Group>
							<Form.Label>Departamento <span className='text-danger'>*</span></Form.Label>
							{ departamento ? (
								<React.Fragment>
									<Form.Control value={departamento} disabled />
									<Form.Text onClick={restartLocations} className="text-link">
										Cambiar Opción
									</Form.Text>
								</React.Fragment>
							) : (
								<Select
									showSearch
									placeholder="Seleccione un departamento"
									optionFilterProp="children"
									onChange={(val, opt) => {
										setDepartamento(opt.label)
										setParentId(val)
										setLocations(null)
									}}
									filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
									options={locations ? locations : []}
									style={{ width: '100%' }}
								/>
							)}
						</Form.Group>
						<Form.Group>
							<Form.Label>Municipio / Ciudad <span className='text-danger'>*</span></Form.Label>
							{ municipio ? (
								<React.Fragment>
									<Form.Control value={municipio} disabled />
									<Form.Text onClick={restartLocations}className="text-link">Cambiar Opción</Form.Text>
								</React.Fragment>
							) : departamento ? (
								<Select
									showSearch
									placeholder="Seleccione un municipio/ciudad"
									optionFilterProp="children"
									onChange={(val, opt) => {
										setMunicipio(opt.label)
										setParentId(val)
										setLocations(null)
									}}
									filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
									options={locations ? locations : []}
									style={{ width: '100%' }}
								/>
							) : (
								<Form.Control value="Seleccione un departamento primero" disabled />
							)}
						</Form.Group>
						{ departamento === 'BOGOTÁ D.C.' && (
							<React.Fragment>
								<Form.Group>
									<Form.Label>Localidad <span className='text-danger'>*</span></Form.Label>
									{ localidad ? (
										<React.Fragment>
											<Form.Control value={localidad} disabled />
											<Form.Text onClick={restartLocations}className="text-link">Cambiar Opción</Form.Text>
										</React.Fragment>
									) : municipio ? (
										<Select
											showSearch
											placeholder="Seleccione una localidad"
											optionFilterProp="children"
											onChange={(val, opt) => {
												setLocalidad(opt.label)
												setParentId(val)
												setLocations(null)
											}}
											filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
											options={locations ? locations : []}
											style={{ width: '100%' }}
										/>
									) : (
										<Form.Control value="Seleccione un municipio primero" disabled />
									)}
								</Form.Group>
							</React.Fragment>
						)}
						<Form.Group>
							<Form.Label>Barrio <span className='text-danger'>*</span></Form.Label>
							{ municipio === 'BOGOTA D.C.' ? (
								<React.Fragment>
									{ barrio ? (
										<Form.Control value={barrio} disabled />
									) : (
										<React.Fragment>
											{ localidad ? (
												<Select
													showSearch
													placeholder="Seleccione un barrio"
													optionFilterProp="children"
													onChange={(val, opt) => {
														setBarrio(opt.label)
														setParentId(val)
														setLocations(null)
													}}
													filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
													options={locations ? locations : []}
													style={{ width: '100%' }}
												/>
											) : (
												<Form.Control value="Seleccione una localidad primero" disabled />
											)}
										</React.Fragment>
									)}
								</React.Fragment>
							) : (
								<Form.Control
									name="barrio"
									value={barrio ? barrio : ''}
									onChange={e => setBarrio(e.target.value)}
									disabled={!municipio}
								/>
							)}
						</Form.Group>
						<Form.Group>
                     <Form.Label>Teléfono de contacto en dirección <span className='text-danger'>*</span></Form.Label>
                     <Form.Control
								type="number"
                        name="address_phone"
                        ref={register({ required: true })}
                     />
                     {errors.address_phone && <Form.Text className="text-warning">Este campo es requerido</Form.Text>}
                  </Form.Group>
						<Button type='submit' color="primary" disabled={!barrio || loading}>
							Crear Dirección Nueva
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}