import asisfarmaApi from '../../services/asisfarmaApi'

export const login = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/auth/login', data)
            .then(response => resolve(response))
            .catch(error => reject(error));
   })
)

export const forgot = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/auth/forgot', data)
            .then(response => resolve(response))
            .catch(error => reject(error));
   })
)

export const logout = () => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/auth/logout')
            .then(response => resolve(response))
            .catch(error => reject(error));
   })
)

export const register = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/auth/signup', data)
            .then(response => resolve(response))
            .catch(error => reject(error));       
   })
)

export const resetPing = token => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post(`/auth/reset/${token}`)
            .then(response => resolve(response))
            .catch(error => reject(error));
   })
)

export const reset = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/auth/reset', data)
            .then(response => resolve(response))
            .catch(error => reject(error));
   })
)

export const activate = token => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get(`/auth/signup/activate/${token}`)
            .then(response => resolve(response))
            .catch(error => reject(error));
   })
)