import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Divider, message } from 'antd'

import AuthLayout from '../../layouts/AuthLayout'

import { forgot } from './services'
import { handleError } from '../../helpers';

export default function Forgot() {
   const { handleSubmit, register, errors, reset } = useForm();
   const [loading, setLoading] = useState(false);

   const onSubmit = async values => {
      setLoading(true)
      await forgot(values)
         .then(response => {
            message.success(`Todo salio bien. ${response.data.message}`, 10)
            setLoading(false)
            reset()
         })
         .catch(error => {
            handleError(error)
            setLoading(false)
         })
   };

   return (
      <AuthLayout>
         <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
               <Form.Label className="bold fs-17 text-secondary">Documento de Identidad</Form.Label>
               <Form.Control
                  name="document"
                  placeholder="Escriba aquí su cédula"
                  ref={register({ required: true })}
               />
               {errors.document && <Form.Text className="text-warning">Este campo es requerido.</Form.Text>}
            </Form.Group>
            <Button type="submit" disabled={loading} className="full-width mb-30 py-10">
               {!loading ? 'Recuperar Contraseña' : 'Cargando...'}
            </Button>
            <Link to="/auth/login" className="text-underline">
               ¿Ya tienes cuenta?
            </Link>
            <Divider type="vertical" />
            <Link to="/auth/register" className="text-underline">
               Crear Cuenta
            </Link>
         </Form>
      </AuthLayout>
   )
}