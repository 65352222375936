import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './theme.css'
import './helpers.css'

import App from './App'

require('dotenv').config()

Bugsnag.start({
   apiKey: '3ccd55b9e997f12ecd965d7f21febedd',
   plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

ReactDOM.render((
   <HashRouter>
      <Provider store={store}>     
         <PersistGate loading="Cargando..." persistor={persistor}>
            <ErrorBoundary>
               <App />
            </ErrorBoundary>
         </PersistGate>
      </Provider>
   </HashRouter>
), document.getElementById('root'))