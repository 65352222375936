import React, {useEffect} from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function SuccessConfirmation({ scope, setPageTitle }){

   useEffect(() => {
      setPageTitle('Entrega Programada')
   }, [ setPageTitle ])

   return (
      <React.Fragment>
         <Container>
            <img  src="/images/icons/success-green.png" width="80px" alt="" />
            <h5 className="bold">Su entrega ha sido programada</h5>
            <p>Podrá consultar su estado a través de nuestra aplicación</p>
            <Link to={`/${scope}`}>
               <Button className="full-width py-10">Ver Entregas</Button>
            </Link>
         </Container>
      </React.Fragment>
   )
}

const Container = styled.div`
   background-color: #fff;
   max-width: 380px;
   padding: 30px 25px;
   margin: auto;
   text-align: center;
   & img {
      display: block;
      margin: 0px auto 20px;      
   }
`;