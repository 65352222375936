import asisfarmaApi from '../../services/asisfarmaApi'

export const getAttachments = params => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get('/attachments', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const storeAttachment = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/attachments', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteAttachment = id => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .delete(`/attachments/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)