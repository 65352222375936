import React, { useState } from 'react'
import { Card, message } from 'antd'
import { Spinner, Form, Button } from 'react-bootstrap'
import { useForm } from "react-hook-form"

import { updateUser } from '../services'
import { handleError } from '../../../helpers'

export default function UserDetails({ user, reload }) {
   const [loading, setLoading] = useState(false)
   const { handleSubmit, register, errors } = useForm();

   const onSubmit = values => {
      setLoading(true)
      if(user.email === values.email) delete values.email
      updateUser(user.id, values)
         .then(() => {
            message.success('Datos actualizados exitosamente')
            reload()
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   };

   return (
      <Card title="Mis Datos">
         {user ? (
            <Form onSubmit={handleSubmit(onSubmit)}>
               <Form.Group>
                  <Form.Label>Nombre Completo</Form.Label>
                  <Form.Control
                     name="name"
                     defaultValue={user.name}
                     ref={register({ required: true })}
                  />
                  {errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text>}
               </Form.Group>
               <Form.Group>
                  <Form.Label>Cédula de Ciudadania</Form.Label>
                  <Form.Control
                     name="document"
                     type="number"
                     defaultValue={user.document}
                     disabled={true}
                  />
               </Form.Group>
               <Form.Group>
                  <Form.Label>Correo Electrónico</Form.Label>
                  <Form.Control
                     name="email"
                     type="email"
                     defaultValue={user.email}
                     ref={register({ required: true })}
                  />
                  {errors.email && <Form.Text className="text-warning">Este campo es requerido.</Form.Text>}
               </Form.Group>
               <Form.Group>
                  <Form.Label>Celular</Form.Label>
                  <Form.Control
                     name="mobile"
                     type="number"
                     defaultValue={user.mobile}
                     ref={register({ required: true, minLength: 10, maxLength: 10 })}
                  />
                  {errors.mobile && <Form.Text className="text-warning">Escriba un celular válido de 10 digitos.</Form.Text>}
               </Form.Group>
               <Form.Group>
                  <Form.Label>Télefono Secundario</Form.Label>
                  <Form.Control
                     name="phone"
                     type="number"
                     defaultValue={user.phone}
                     ref={register({ minLength: 7, maxLength: 10 })}
                  />
                  {errors.phone && <Form.Text className="text-warning">Escriba un teléfono válido de 7 a 10 digitos.</Form.Text>}
               </Form.Group>
               <div className="text-right mt-20">
                  <Button
                     type="submit"
                     variant="outline-primary"
                     disabled={loading}
                  >
                     {!loading ? 'Actualizar Datos' : 'Cargando...'}
                  </Button>
               </div>
            </Form>
         ) : (
               <div className="text-center">
                  <Spinner animation="border" />
               </div>
            )}
      </Card>
   )
}