import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from "react-hook-form";
import { Form, Button } from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom'
import { message } from 'antd'

import AuthLayout from '../../layouts/AuthLayout'

import { register as registerService, login } from './services'
import { userLogin } from './actions'
import { handleError } from '../../helpers';

export default function Register() {
   const dispatch = useDispatch()
   const { handleSubmit, register, errors } = useForm();
   const [loading, setLoading] = useState(false);
   const authToken = useSelector(state => state.auth.token)

   if (authToken) return <Redirect to="/" />

   const onSubmit = values => {
      setLoading(true)
      registerService(values)
         .then(response => {
            message.success(`Todo salio bien. ${response.data.message}`)
            !authToken && login(values)
               .then(response => (
                  !authToken && dispatch(userLogin(response.data))
               ))
               .catch(error => {
                  message.warning(`Algo salio mal. ${error.message}`)
                  setLoading(false)
               })
         })
         .catch(error => {
            handleError(error)
            setLoading(false)
         })
   };

   return (
      <AuthLayout>
         <Form onSubmit={handleSubmit(onSubmit)} className="mb-30">
            <Form.Group>
               <Form.Label className="bold fs-17 text-secondary">Nombre Completo</Form.Label>
               <Form.Control
                  name="name"
                  placeholder="Escriba su nombre completo"
                  ref={register({ required: true })}
               />
               {errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text>}
            </Form.Group>
            <Form.Group>
               <Form.Label className="bold fs-17 text-secondary">Correo Electrónico</Form.Label>
               <Form.Control
                  name="email"
                  type="email"
                  placeholder="Escriba aquí su correo"
                  ref={register({ required: true })}
               />
               {errors.email && <Form.Text className="text-warning">Este campo es requerido. {errors.email.message}</Form.Text>}
            </Form.Group>
            <Form.Group>
               <Form.Label className="bold fs-17 text-secondary">Documento de Identidad</Form.Label>
               <Form.Control
                  name="document"
                  placeholder="Escriba su cédula o tarjeta de identidad"
                  ref={register({ required: true })}
               />
               {errors.document && <Form.Text className="text-warning">Este campo es requerido. {errors.document.message}</Form.Text>}
            </Form.Group>
            <Form.Group>
               <Form.Label className="bold fs-17 text-secondary">Confirmar Documento de Identidad</Form.Label>
               <Form.Control
                  name="document_confirmation"
                  placeholder="Escriba de nuevo su documento"
                  ref={register({ required: true })}
               />
               {errors.document_confirmation && <Form.Text className="text-warning">Este campo es requerido. {errors.document_confirmation.message}</Form.Text>}
            </Form.Group>
            <Form.Group>
               <Form.Label className="bold fs-17 text-secondary">Número Celular</Form.Label>
               <Form.Control
                  name="mobile"
                  type="number"
                  placeholder="Escriba su número celular"
                  ref={register({ required: true, minLength: 10, maxLength: 10 })}
               />
               {errors.mobile && <Form.Text className="text-warning">Ingrese un celular válido de 10 dígitos.</Form.Text>}
            </Form.Group>
            <Form.Group>
               <Form.Label className="bold fs-17 text-secondary">Contraseña</Form.Label>
               <Form.Control
                  name="password"
                  type="password"
                  placeholder="Escriba una contraseña"
                  ref={register({ required: true, minLength: 6 })}
               />
               {errors.password && <Form.Text className="text-warning">Escriba una contraseña de almenos 6 caracteres.</Form.Text>}
            </Form.Group>
            <Form.Group>
               <Form.Label className="bold fs-17 text-secondary">Confirmar Contraseña</Form.Label>
               <Form.Control
                  name="password_confirmation"
                  type="password"
                  placeholder="Confirme de nuevo la contraseña"
                  ref={register({ required: true })}
               />
               {errors.password_confirmation && <Form.Text className="text-warning">Este campo es requerido.</Form.Text>}
            </Form.Group>
            <Button type="submit" disabled={loading} className="full-width mb-30 py-10 mt-10">
               {!loading ? 'Crear Cuenta' : 'Cargando...'}
            </Button>
            <Link to="/auth/login" className="text-underline">
               ¿Ya tienes cuenta?
            </Link>
         </Form>
      </AuthLayout>
   )
}