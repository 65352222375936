import React from 'react'
import styled from 'styled-components'
import { Empty } from 'antd'

const Container = styled.div`
   height: 100vh;
   padding-top: 30vh;
`;

export default function Error404() {
   return (
      <Container>
         <Empty description="Esta página no existe." />
      </Container>
   )
}