import { message, Popconfirm } from 'antd'
import React from 'react'
import { Button } from 'react-bootstrap'
import { handleError } from '../../../helpers'

import { colors } from '../../styles'
import { deleteUser } from '../services'

export default function UserCard({ user, reload }) {

	const handleDelete = () => {
		deleteUser(user.id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<div style={styles.card}>
				<div style={styles.type}>
					<div style={{ flex:1 }}>
						<p style={styles.type_text}>{user.name}</p>
					</div>
				</div>
				<p style={styles.title}>
					Documento: {user.document}
				</p>
				<p style={styles.subtitle}>
					Beneficiario asociado
				</p>
				<Popconfirm
					title="Esta acción no puede revertirse"
					okText="Eliminar"
					cancelText="Cancelar"
					okButtonProps={{ danger:true }}
					onConfirm={handleDelete}
				>
					<Button
						variant='outline-danger'
						size="sm"
						disabled={user.deliveries_count > 0}
					>
						Eliminar Beneficiario
					</Button>
				</Popconfirm>
			</div>
		</React.Fragment>
	)
}

const styles = {
	card: {
		borderBottomWidth: 1,
		borderTopWidth: 1,
		borderColor: colors.light,
		padding: 13,
		marginBottom: 10,
		backgroundColor: 'white'
	},
	type: {
		flexDirection: 'row',
		marginBottom: 0,
		alignItems: 'center'
	},
	type_text: {
		color: colors.secondary,
		fontSize: 16,
		fontWeight: 'bold',
		marginBottom: 0,
	},
	title: {
		color: colors.secondary,
		fontSize: 15,
		marginBottom: 0
	},
	subtitle: {
		color: colors.secondary,
		fontSize: 12,		
		marginBottom: 15,
	},
}