import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default function AuthLayout({ children }) {
   return (
      <FullWidthContainer>
         <div className="text-center mb-20 bg-white py-20">
            <Link to="/login">
               <img src="/images/logo.png" alt="Asisfarma Logo" width="210px" />
            </Link>
         </div>
         <ContentContainer>
            {children}
         </ContentContainer>
         <Footer>
            <Row className="mb-20 ml-0 mr-0">
               <Col className="text-right" style={{ paddingTop: '1px' }}>
                  <a href="https://apps.apple.com/us/app/asisfarma/id1547879434" target="_blank" rel="noopener noreferrer">
                     <img src="/images/download_appstore.png" alt="Descargar App Store" width="125px" />
                  </a>
               </Col>
               <Col className="text-left">
                  <a href="https://play.google.com/store/apps/details?id=com.instafarma.asisfarma" target="_blank" rel="noopener noreferrer">
                     <img src="/images/download_playstore.png" alt="Descargar Play Store" width="150px" />
                  </a>
               </Col>
            </Row>
            Asisfarma ©{moment().format('YYYY')} - Una dosis de vida
         </Footer>
      </FullWidthContainer>
   )
}

const FullWidthContainer = styled.div`
   background-color: #eff2f7;
   min-height: 100vh;
   padding-top: 7vh;
`;

const ContentContainer = styled.div`   
   padding: 20px 10px 20px;
   width: 100%;
   max-width: 350px;
   margin: auto;
`;

const Footer = styled.div`
   text-align: center;
   color: #888;
   margin: 25px auto;
   padding-bottom: 30px;
`;