import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import Empty from '../../components/Empty'
import { handleError } from '../../helpers'
import AppLayout from '../../layouts/AppLayout'
import NewUserModal from './partials/NewUserModal'
import UserCard from './partials/UserCard'
import { getUsers } from './services'

export default function RelatedUsersList() {
	const { user } = useSelector(state => state.auth)
	const [users, setUsers] = useState(null)
	const [showNewUserModal, setShowNewUserModal] = useState(false)

	useEffect(() => {
		!users && getUsers({
			'filter[parent_id]': user.id,
			include: 'deliveriesCount'
		})
			.then(res => setUsers(res.data.data))
			.catch(error => handleError(error))
	}, [ users, user ])

	return (
		<AppLayout title="Beneficiarios">
			{ users ? (
				<React.Fragment>
					{users.length > 0 ? (
						<React.Fragment>
							<Row>
								{users.map(user => (
									<Col key={user.id} md="6" xl="4">
										<UserCard 
											user={user}  
											reload={() => setUsers(null)}
										/>
									</Col>
								))}
							</Row>
						</React.Fragment>
					) : (
						<Empty
							title={`No encontramos beneficiarios a su nombre`}
							description="Encuentre aquí los beneficiarios asociados a su cuenta."
						/>
					)}
					<div className="text-center mt-30">
						<Button
							variant="primary" 
							onClick={() => setShowNewUserModal(true)}
						>
							Agregar Beneficiario
						</Button>
					</div>
				</React.Fragment>
			) : <Spinner animation="border" />}
			{ showNewUserModal && (
				<NewUserModal 
					visible
					onClose={() => setShowNewUserModal(false)}
					reload={() => setUsers(null)}
				/>
			)}
		</AppLayout>
	)
}