import React from 'react'
import styled from 'styled-components'

import { faHospital, faLifeRing, faMotorcycle, faUserAlt, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

export default function SideMenu({ handleShowMenu, showMenu }) {
   const currentPath = window.location.pathname.split('/')[1]

   return (
      <React.Fragment>
         <BkgOverlay onClick={handleShowMenu} show={showMenu}>
            <DropdownContainer show={showMenu}>
               <span className="px-15 mb-30 block">Menu Principal</span>
               <Link to="/deliveries">
                  <MenuLink className={ currentPath==='deliveries' && 'active' }>
                     <FontAwesomeIcon icon={faMotorcycle} className="mr-15" />
                     Entregas a Domicilio
                  </MenuLink>
               </Link>
               <Link to="/pickups">
                  <MenuLink className={ currentPath==='pickups' && 'active' }>
                     <FontAwesomeIcon icon={faHospital} className="mr-15" />
                     Entregas en Farmacia
                  </MenuLink>
               </Link>
               <Link to="/users">
                  <MenuLink className={ currentPath==='users' && 'active' }>
                     <FontAwesomeIcon icon={faUsers} className="mr-15" />
                     Beneficiarios
                  </MenuLink>
               </Link>
               <Link to="/profile">
                  <MenuLink className={ currentPath==='profile' && 'active' }>
                     <FontAwesomeIcon icon={faUserAlt} className="mr-15" />
                     Mi Perfil
                  </MenuLink>
               </Link>
               <Link to="/tickets">
                  <MenuLink className={ currentPath==='tickets' && 'active' }>
                     <FontAwesomeIcon icon={faLifeRing} className="mr-15" />
                     Soporte & Novedades
                  </MenuLink>
               </Link>
            </DropdownContainer>
         </BkgOverlay>
      </React.Fragment>
   )
}

const BkgOverlay = styled.div`
   display: ${props => !props.show && "none"};
   position: fixed;
   top: 70px;
   left: 0px;
   z-index: 100;
   width: 100%;
   height: 100%;
   background-color: #00000050
`;

const DropdownContainer = styled.div`
   display: ${props => !props.show && "none"};   
   z-index: 10;
   background-color: #00469A;
   position: sticky;
   left: 0px;
   top: 70px;
   text-align: left;
   border: none;
   width: 300px;
   height: 100%;
   padding: 20px 0px;
   color: #fff;
`;

const MenuLink = styled.div`
   padding: 20px 30px;
   font-size: 18px;
   color: #fff;   
   &:hover {
      background-color: #0b0b84;
      cursor: pointer;
      color: #fff;
      text-decoration: none;
   }
   &.active {
      background-color: #0b0b84;
      color: #ccc;
   }
`;