import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Spinner, Row, Col, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import DeliveryPickUpCard from './partials/DeliveryPickupCard'
import RelatedUsersSelect from '../Users/partials/RelatedUsersSelect'
import Empty from '../../components/Empty'

import { getDeliveries } from './services'
import { handleError } from '../../helpers'

export default function DeliveriesList({ setPageTitle }) {
   const { user } = useSelector(state => state.auth)
   const [deliveries, setDeliveries] = useState(null)
   const [selectedUserId, setSelectedUserId] = useState(user.id)
   const history = useHistory()

   useEffect(() =>  setPageTitle('Entregas a Domicilio'), [ setPageTitle ])
   
   useEffect(() => {
      !deliveries && getDeliveries({
			"filter[user_id]": selectedUserId,
			sort: "-created_at",
         [`filter[deliveries]`]: 1,
         include: 'store.address,address'
		})
         .then(response => setDeliveries(response.data.data))
         .catch(error => handleError(error))
   }, [deliveries, selectedUserId])

   return (
      <React.Fragment>
         <RelatedUsersSelect
            selectedUserId={selectedUserId}
            setSelectedUserId={setSelectedUserId}
            reload={() => setDeliveries(null)}
         />
         { deliveries ? (
            <React.Fragment>
               {deliveries.length > 0 ? (
                  <React.Fragment>
                     <Row>
                        {deliveries.map(delivery => (
                           <Col key={delivery.id} md="6" xl="4">
                              <DeliveryPickUpCard 
                                 scope="deliveries"
                                 delivery={delivery}
                                 reload={() => setDeliveries(null)}
                              />
                           </Col>
                        ))}
                     </Row>
                  </React.Fragment>
               ) : (
                  <Empty
                     title={`No encontramos entregas a Domicilio`}
                     description="Encuentre aquí sus entregas programadas y finalizadas tan pronto agende una."
                  />
               )}
               <div className="text-center mt-30">
                  <Button 
                     variant="primary" 
                     onClick={() => history.push(`/deliveries/new`)}
                  >
                     Solicitar Nueva Entrega
                  </Button>
               </div>
            </React.Fragment>
         ) : <Spinner animation="border" /> }
      </React.Fragment>
   )
}