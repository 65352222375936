import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import authReducer from "./containers/Auth/reducer"

const rootPersistConfig = {
   key: 'root',
   storage: storage   
 };

const authPersistConfig = {
   key: 'token',
   storage: storage
 };
 
 const rootReducer = combineReducers({
   auth: persistReducer(authPersistConfig, authReducer)   
 });
 
export default persistReducer(rootPersistConfig, rootReducer);
