import React from 'react'
import { Card, message, Popconfirm } from 'antd'
import styled from 'styled-components'

import Empty from '../../../components/Empty'

import { deleteAddress } from '../services'
import { handleError } from '../../../helpers'
import { Button } from 'react-bootstrap'

export default function UserAddresses({ addresses, reload }) {

   const handleDelete = id => {
      deleteAddress(id) 
         .then(res => {
            message.info(res.data.message)
            reload()
         })
         .catch(handleError)
   }

   return (
      <Card title="Mis Direcciones">
         { addresses.length > 0 ? (
            <React.Fragment>
               { addresses.map( address => (
                  <Container key={address.id}>
                     <AddressTitle>{address.address} {address.details}</AddressTitle>
                     <AddressDetails>
                        {address.barrio} {address.localidad} - {address.municipio}, {address.departamento}
                     </AddressDetails>
                     <Popconfirm
                        title="Esta acción no puede revertirse"
                        okText="Eliminar"
                        cancelText="Cancelar"
                        okButtonProps={{ danger:true }}
                        onConfirm={() => handleDelete(address.id)}
                     >
                        <Button variant='outline-danger' size="sm">Eliminar</Button>
                     </Popconfirm>
                  </Container>
               )) }
            </React.Fragment>
         ) : <Empty 
            title="No tiene direcciones asociadas" 
            description="Al programar una entrega podrá crear una dirección nueva." 
         />}
      </Card>
   )
}

const Container = styled.div`
   padding: 10px;
   border: 1px solid #e8e8e8;
   margin-bottom: 5px;
   background-color: #eff2f7;
`

const AddressTitle = styled.h5`
   color: #333;
   font-size: 16px;
`

const AddressDetails = styled.p`
   margin-bottom: 10px;
   color: #888;
`