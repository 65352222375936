import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { Spinner } from 'react-bootstrap'
import { message } from 'antd'
import AppLayout from '../../layouts/AppLayout'

import { logout } from './services'
import { userLogout } from './actions'

export default function Logout() {
   const dispatch = useDispatch();
   const authToken = useSelector(state => state.auth.token)

   authToken && logout()
      .then(() => {
         message.success('Ha cerrado su sesión exitosamente')
         authToken && dispatch(userLogout())
      })
      .catch(error => {
         message.error(`Algo salio mal. ${error.message}`)
         authToken && dispatch(userLogout())
      })

   if (!authToken) return <Redirect to="/auth/login" />

   return (
      <AppLayout>
         <Spinner animation="grow" />
      </AppLayout>
   )
}