import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import moment from 'moment'
import 'moment/locale/es'

import VoidDeliveryButton from './VoidDeliveryButton'
import { colors } from '../../styles'
import { Divider } from 'antd'

export default function DeliveryPickUpCard({ scope, delivery, reload }) {
	const address = scope === 'deliveries' ? delivery.address : delivery.store.address

	return (
		<React.Fragment>
			<div key={delivery.id} style={styles.card}>
				<div style={styles.type}>
					<div style={{ flex:1 }}>
						<div style={{ ...styles.badge, backgroundColor: delivery.status_badge.color }}>
							<p style={styles.badgep}>{delivery.status_badge.label}</p>
						</div>
						<p style={styles.typep}>
							Entrega { scope === 'deliveries' ? 'a Domicilio' : 'en Farmacia' }{" "}
						</p>
					</div>
				</div>
				<p style={styles.title}>
					{address.address} | {address.details} - {address.barrio} {address.localidad}, {address.municipio}, {address.departamento}
				</p>
				<p style={styles.date}>
					{moment(delivery.schedule_date).format('dddd DD MMMM YYYY').toUpperCase()}
				</p>
				{ delivery.status === 'pendding' && (
					<React.Fragment>
						<Link to={`/${scope}/${delivery.id}/attachments`}>
							<Button variant='outline-primary'>
								Ver y Editar Adjuntos
							</Button>
						</Link>
						<Divider type="vertical" />
						<VoidDeliveryButton 
							delivery={delivery}
							reload={reload}
						/>
					</React.Fragment>
				)}
			</div>
		</React.Fragment>
	)
}

const styles = {
	card: {
		borderBottomWidth: 1,
		borderTopWidth: 1,
		borderColor: colors.light,
		padding: 13,
		marginBottom: 10,
		backgroundColor: 'white'
	},
	type: {
		flexDirection: 'row',
		marginBottom: 10,
		alignItems: 'center'
	},
	typep: {
		color: colors.primary,
		fontSize: 16,
		fontWeight: '500',
		marginBottom: 0
	},
	title: {
		color: colors.secondary,
		fontSize: 15,
		marginBottom: 5
	},
	date: {
		color: colors.secondary,
		fontSize: 14,
		fontWeight: 'bold'
	},
	badge: {
		backgroundColor: colors.secondary,
		height: 25,
		borderRadius: 50,
		marginRight: 5,
		float: 'right',
		padding: '3px 7px',
	},
	badgep: {
		color:'white',
		fontSize: 13,
		fontWeight: 'bold',
		textAlign: 'center',
		marginBottom: 0
	}
}