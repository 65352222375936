import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from "react-hook-form"
import { useHistory } from 'react-router-dom'
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap'
import { DatePicker, message } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/es_ES'

import RelatedUsersSelect from '../../Users/partials/RelatedUsersSelect'

import { storeDelivery } from '../../Deliveries/services'
import { getStores } from '../../Stores/services'
import { handleError } from '../../../helpers'

export default function NewPickupForm({ setPageTitle }) {
   const { handleSubmit, register, errors, setValue, setError, clearError } = useForm()
   const [stores, setStores] = useState(null)
   const [loading, setLoading] = useState(false)
   const [selectedStoreId, setSelectedStoreId] = useState(null)
   const userId = useSelector(state => state.auth.user.id)
   const [selectedUserId, setSelectedUserId] = useState(userId)
   const history = useHistory()
   const currentTime = moment().format('HH')

   useEffect(() => { setPageTitle('Recoger en Farmacia / IPS') }, [ setPageTitle ])

   useEffect(() => {
      !stores && getStores({ include: 'address' })
         .then(res => setStores(res.data.data))
         .catch(error => handleError(error))
   }, [ stores ])

   const onSubmit = values => {
      setLoading(true)
      storeDelivery({
         schedule_timerange: 'L-V 8am a 5pm | S 8am a 1pm',
         user_id: selectedUserId,
         price_terms: '$0 COP',
         store_id: selectedStoreId,
			...values
		})
			.then(res => {
            message.success('Entrega solicitada exitosamente')
            history.push(`/pickups/${res.data.data.id}/attachments`)
         })
			.catch(error => console.error(handleError(error)))
   };

   const validateDate = (value) => {
      let newDate = moment(value).format('YYYY-MM-DD')
      let minDate = moment().add(currentTime < 16 ? 1 : 2, 'days').format('YYYY-MM-DD')
      let maxDate = moment().add(21, 'days').format('YYYY-MM-DD')
      if (moment(newDate).isSameOrAfter(minDate) && moment(newDate).isBefore(maxDate)) {
         setValue('schedule_date', newDate)
         clearError('schedule_date')
      } else {
         setError('schedule_date')
         setValue('schedule_date', '')
      }
   }

   if(!stores) return <Spinner />

   return (
      <Form onSubmit={handleSubmit(onSubmit)}>
         <Row className="px-10">
            <Col sm="12">
               <Form.Label>Paciente en la autorización *</Form.Label>
               <RelatedUsersSelect
                  selectedUserId={selectedUserId}
                  setSelectedUserId={setSelectedUserId}
               />
            </Col>
            <Form.Group as={Col} md="6">
               <Form.Label>Fecha de Entrega Sugerida *</Form.Label>
               <DatePicker
                  format="YYYY-MM-DD"
                  disabledDate={current => {
                     let result = false
                     if(current){
                        if(current <= moment().add(currentTime < 16 ? 0 : 1, 'days')) result = true
                        if(current > moment().add(21, 'days')) result = true
                        if(current.weekday() === 6) result = true
                     }
                     return result
                  }}
                  placeholder="Eliga una fecha"
                  className="full-width"
                  onChange={value => validateDate(value)}
                  locale={locale}
               />
               <input
                  type="hidden"
                  name="schedule_date"
                  ref={register({ required: true })}
               />
               {errors.schedule_date && <Form.Text className="text-warning">
                  Disponible para entregar desde el {moment().add(1, 'days').format('YYYY-MM-DD')} 
                  hasta el ${moment().add(21, 'days').format('YYYY-MM-DD')}
               </Form.Text>}
            </Form.Group>
            <Form.Group as={Col} md="6">
               <Form.Label>Franja de Entrega Sugerida</Form.Label>
               <Form.Control 
                  value="L-V 8am a 5pm | S 8am a 1pm" 
                  disabled 
               />
               <Form.Text className="text-info">* Los tiempos de entrega pueden variar según la operación logística.</Form.Text>
            </Form.Group>
            <Form.Group as={Col} md="12">
               <Form.Label>Farmacia para Recolección</Form.Label>
               <Form.Control
                  as="select"
                  name="address_id"
                  ref={register({ required: true })}
                  onChange={e => setSelectedStoreId(e.target[e.target.selectedIndex].getAttribute('data-store-id'))}
               >
                  <option value="">::Eliga una farmacia::</option>
                  { stores.map(store => (
                     <option key={store.id} value={store.address.id} data-store-id={store.id}>
                        {store.address.address + " " + store.address.details + " " + store.address.municipio + ", " + store.address.departamento}
                     </option>
                  )) }
               </Form.Control>
               { errors.address_id && <Form.Text className="text-warning">Este campos es requerido.</Form.Text>}
            </Form.Group>
            <FormFooter>
               <Button
                  type="submit"
                  className="full-width py-10"
                  disabled={loading || !setSelectedStoreId}
               >
                  {!loading ? "Confirmar y Programar Recogida" : "Cargando..."}
               </Button>
            </FormFooter>
         </Row>
      </Form>
   )
}

const FormFooter = styled.div`
   max-width: 450px;
   margin: auto;
   padding: 20px 30px;
`;