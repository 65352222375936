import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'

import Auth from './containers/Auth'
import Deliveries from './containers/Deliveries'
import Tickets from './containers/Tickets'
import Users from './containers/Users'
import Pickups from './containers/Pickups'

import Error404 from './containers/Errors/404'
import Profile from './containers/Profile/UserProfile'

function App() {  
  const authToken = useSelector(state => state.auth.token)

  const handleOnIdle = event => {
    if(authToken) window.location.href = '/#/auth/logout'
  }

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500
  })

  return (
    <Switch>
      <Redirect exact path="/" to="/deliveries" />
      
      <Route path="/auth" component={Auth} />
      {!authToken && <Redirect to="/auth/login" />}      

      <Route path="/deliveries" component={Deliveries} />
      <Route path="/pickups" component={Pickups} />
      <Route path="/tickets" component={Tickets} />
      <Route path="/profile" component={Profile} />
      <Route path="/users" component={Users} />
     
      <Route component={Error404} />
    </Switch>
  )
}

export default App