import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { Card, Empty, Descriptions } from 'antd'
import { Spinner, Col, Row, Badge } from 'react-bootstrap'
import styled from 'styled-components'

import NewTicketForm from './NewTicketForm'
import AppLayout from '../../layouts/AppLayout'
import { getTickets } from './services'
import { handleError } from '../../helpers'

export default function Tickets() {
   const user = useSelector(state => state.auth.user)
   const [tickets, setTickets] = useState(null)
   
   useEffect(()=>{
      !tickets && getTickets({ "filter[user_id]": user.id, include: "ticketType,responses" })
         .then(res => setTickets(res.data.data))
         .catch(error => handleError(error))
   }, [tickets, user])

   return (
      <AppLayout title="Reportar Novedad">
         <Container>
            <Card title="Crear solicitud nueva">
               <NewTicketForm 
                  userId={user.id} 
                  reload={()=>setTickets(null)} 
               />
            </Card>
            <Card title="Historial de Solicitudes">
               { tickets ? (
                  <React.Fragment>
                     { tickets.length > 0 ? (
                        <Row>
                           { tickets.map(ticket => (
                              <Card key={ticket.id} as={Col} md="6" className="mx-10 bg-light">
                                 <Descriptions column={2}>
                                    <Descriptions.Item label="Id">#{ticket.id}</Descriptions.Item>
                                    <Descriptions.Item className="text-right">
                                       <Badge variant="info">
                                          {ticket.state === 'open'&&'Abierto'}
                                          {ticket.state === 'solved'&&'Cerrado'}
                                          {ticket.state === 'pendding'&&'Pendiente'}
                                       </Badge>
                                    </Descriptions.Item>
                                    <Descriptions.Item span={2} label="Motivo">{ticket.ticket_type.name}</Descriptions.Item>
                                    <Descriptions.Item span={2} label="Mensaje">{ticket.message}</Descriptions.Item>
                                 </Descriptions>
                                 { ticket.responses.length > 0 ? (
                                    <React.Fragment>
                                       {ticket.responses.map( response => (
                                          <Card key={response.id} className="p-0 mt-10 mb-0 bg-white">
                                             {response.message}
                                          </Card>
                                       ) )}
                                    </React.Fragment>
                                 ) : (
                                    <Card className="p-0 mt-10 mb-0 bg-white">
                                       Sin respuestas
                                    </Card>
                                 )}
                              </Card>
                           )) }
                        </Row>
                     ) : <Empty description="No tiene solicitudes" />}
                  </React.Fragment>
               ) : <Spinner />}
            </Card>
         </Container>
      </AppLayout>
   )
}

const Container = styled.div`
   max-width: 450px;
   margin: auto;
`;