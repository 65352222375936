import asisfarmaApi from '../../services/asisfarmaApi'

export const getUsers = params => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get('/users', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const storeUser = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/users', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteUser = id => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .delete(`/users/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)