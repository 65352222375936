import React from 'react'
import AWS from "aws-sdk"
import { Form } from 'react-bootstrap'
import { Upload, Button, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { storeAttachment } from '../services'

export default function NewAttachmentForm({ deliveryId, reload }) {
	const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID
	const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY

	const props = formName => ({
		size: 'small',
		multiple: false,
		customRequest({
			file,
			onError,
			onProgress,
			onSuccess,
		}) {
			AWS.config.update({
				accessKeyId,
				secretAccessKey
			});
		
			const S3 = new AWS.S3({
				params: { Bucket: 'instafarma' },
				region: 'us-east-1',
		  	});

		  	let fileExt = file.name.split('.').pop()
		  	let uri = `asisfarma/attachments/${Date.now()}.${fileExt}`
		
			const objParams = {
				Bucket: "instafarma",
				Key: uri,
				Body: file,
				ContentType: file.type
			};
		
			S3.putObject(objParams)
				.on("httpUploadProgress", function({ loaded, total }) {
					onProgress(
						{ percent: Math.round((loaded / total) * 100) },
						file
					);
				})
				.send(function(err, data) {
					if (err) {
						onError();
						console.error('Error al subir imagen: ', err.message)
					} else {
						onSuccess(data.response, file);
						storeAttachment({
							delivery_id: deliveryId,
							url: `https://instafarma.s3.us-east-1.amazonaws.com/${uri}`
						})
							.then(res => {
								message.success(res.data.message)
								reload()
							})
					}
				});
		}
	})

	return (
		<React.Fragment>
			<Form.Group className="mb-10">
				<div>
					<Form.Label>Subir Fórmula Médica y MIPRES <small>(sí aplica)</small></Form.Label>
				</div>
				<Upload {...props('mipres_url')}>
					<Button icon={<UploadOutlined />}>Agregar Adjunto</Button>
				</Upload>
			</Form.Group>
		</React.Fragment>
	)
}