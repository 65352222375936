import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Spinner } from 'react-bootstrap'

import AppLayout from '../../layouts/AppLayout'
import UserAddresses from './partials/UserAddresses'
import UserDetails from './partials/UserDetails'
import { getUser } from './services'
import { handleError } from '../../helpers'

export default function Profile() {
   const auth = useSelector(state => state.auth)
   const [user, setUser] = useState(null)

   useEffect(()=>{
      !user && getUser(auth.user.id, { include: 'addresses' })
         .then(res => setUser(res.data.data))
         .catch(error => handleError(error))
   }, [user, auth])

   return (
      <AppLayout title="Mi Perfil">
         { user ? (
            <Row>
               <Col sm="6">
                  <UserDetails user={user} reload={()=>setUser(null)} />
               </Col>
               <Col sm="6">
                  <UserAddresses addresses={user.addresses} reload={()=>setUser(null)} />
               </Col>
            </Row>
         ) : (
            <div className="text-center">
               <Spinner animation="border" />
            </div>
         )}
      </AppLayout>
   )
}