import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from "react-hook-form"
import { useHistory } from 'react-router-dom'
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap'
import { DatePicker, message } from 'antd'
import locale from 'antd/es/date-picker/locale/es_ES'
import moment from 'moment'
import styled from 'styled-components'
import Bugsnag from '@bugsnag/js'

import RelatedUsersSelect from '../../Users/partials/RelatedUsersSelect'
import NewAddressModal from './NewAddressModal'

import { getAddresses, storeDelivery } from '../services'
import { handleError } from '../../../helpers'
import { getLocations } from '../../Locations/services'

export default function NewDeliveryForm({ setPageTitle }) {
   const { handleSubmit, register, errors, setValue, watch, setError, clearError } = useForm()
   const [newAddress, setNewAddress] = useState(false)
   const [userAddresses, setUserAddresses] = useState(null)
   const [priceTerms, setPriceTerms] = useState(null)
   const [loading, setLoading] = useState(false)
   const userId = useSelector(state => state.auth.user.id)
   const [selectedUserId, setSelectedUserId] = useState(userId)
   const history = useHistory()
   const maxDaysAhead = 21
   const currentTime = moment().format('HH')

   useEffect(() => setPageTitle('Entrega a Domicilio'), [ setPageTitle ])

   useEffect(() => {
      !userAddresses && getAddresses({ "filter[user_id]": userId })
         .then(res => setUserAddresses(res.data.data))
         .catch(err => handleError(err))
   }, [userAddresses, userId, watch])

   const onSubmit = values => {
      setLoading(true)
      storeDelivery({ ...values, price_terms: priceTerms, user_id: selectedUserId })
			.then(res => {
            message.success('Entrega solicitada exitosamente')
            history.push(`/deliveries/${res.data.data.id}/attachments`)
         })
			.catch(error => {
            handleError(error)
            setLoading(false)
         })
   }

   const validateDate = (value) => {
      let newDate = moment(value).format('YYYY-MM-DD')
      let minDate = moment().add(currentTime < 16 ? 1 : 2, 'days').format('YYYY-MM-DD')
      let maxDate = moment().add(maxDaysAhead, 'days').format('YYYY-MM-DD')
      const holidays = ['2024-05-01','2024-05-13','2024-06-03','2024-06-10','2024-07-01','2024-07-20','2024-08-07','2024-08-19','2024-10-14','2024-11-04','2024-11-11','2024-12-08','2024-12-25','2025-01-01','2025-01-06','2025-03-24','2025-04-17','2025-04-18','2025-05-01','2025-06-02','2025-06-23','2025-06-30','2025-07-20','2025-08-07','2025-08-18','2025-10-13','2025-11-03','2025-11-17','2025-12-08','2025-12-25']

      if ( moment(newDate).isSameOrAfter(minDate) && 
         moment(newDate).isBefore(maxDate) &&
         !holidays.includes(moment(newDate).format('YYYY-MM-DD'))
      ) {
         setValue('schedule_date', newDate)
         clearError('schedule_date')
      } else {
         setError('schedule_date')
         setValue('schedule_date', '')
      }
   }

   const checkPriceTerms = () => {
      const addressId = watch('address_id')
      const opts = userAddresses.filter(r => r.id === parseInt(addressId))
      const currentAddress = opts.length > 0 ? opts[0] : null

      if(!currentAddress) return setPriceTerms(null)

      getLocations({ 'filter[type]': 'municipio', 'filter[name]': currentAddress.municipio })
         .then(res => {
            if(res.data.data.length === 0) 
               return Bugsnag.notify(new Error(`Dirección ID ${currentAddress.id} no tiene un municipio con terminos de precio`))

            setPriceTerms(res.data.data[0].price_terms)
         })
         .catch(error => handleError(error))
   }

   if (!userAddresses)
      return <div className="text-center"><Spinner animation="border" /></div>

   return (
      <React.Fragment>
         <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="px-10">
               <Col sm="12">
                  <RelatedUsersSelect
                     selectedUserId={selectedUserId}
                     setSelectedUserId={setSelectedUserId}
                  />
                  <Form.Text className='text-muted mb-15'>La cédula en la formúla médica debe coincidir con el usuario que seleccione. Recuerde que puede agregar beneficiarios a su cuenta desde el menú principal.</Form.Text>
               </Col>
               <Form.Group as={Col} md="6">
                  <Form.Label>Fecha de Entrega Sugerida *</Form.Label>
                  <DatePicker
                     format="YYYY-MM-DD"
                     disabledDate={current => {
                        let result = false
                        if(current){
                           if(current <= moment().add(currentTime < 16 ? 0 : 1, 'days')) result = true
                           if(current > moment().add(maxDaysAhead, 'days')) result = true
                           if(current.weekday() === 6) result = true
                        }
                        return result
                     }}
                     placeholder="Eliga una fecha"
                     className="full-width"
                     onChange={value => validateDate(value)}
                     locale={locale}
                  />
                  <input
                     type="hidden"
                     name="schedule_date"
                     ref={register({ required: true })}
                  />
                  {errors.schedule_date && <Form.Text className="text-warning">
                     Disponible para entregar desde el {moment().add(1, 'days').format('YYYY-MM-DD')} hasta el {moment().add(maxDaysAhead, 'days').format('YYYY-MM-DD')}
                  </Form.Text>}
                  <Form.Text className='text-info'>(Esta fecha puede variar dependiendo los tiempos de la transportadora)</Form.Text>
               </Form.Group>
               <Form.Group as={Col} md="6">
                  <Form.Label>Franja de Entrega Sugerida</Form.Label>
                  <Form.Control value="L-V 8am a 5pm | S 8am a 1pm" disabled />
                  <Form.Text className="text-info">* Los tiempos de entrega pueden variar según la operación logística.</Form.Text>
               </Form.Group>
               <Form.Group as={Col} md="12">
                  <Form.Label>Dirección de Entrega</Form.Label>
                  <Form.Control
                     as="select"
                     name="address_id"
                     ref={register({ required: true })}
                     onChange={() => {
                        (watch('address_id') === 'new') ? setNewAddress(true) : setNewAddress(false)
                        checkPriceTerms()
                     }}
                  >
                     <option value="">::Eliga una opción::</option>
                     {userAddresses.map(userAddress => (
                        <option value={userAddress.id} key={userAddress.id}>
                           {userAddress.address + " " + userAddress.details + " " + userAddress.municipio + ", " + userAddress.departamento}
                        </option>
                     ))}
                     <option value="new">- Ingresar una Nueva -</option>
                  </Form.Control>
                  {errors.address_id && <Form.Text className="text-warning">Este campo es requerido</Form.Text>}
               </Form.Group>
               <FormFooter>
                  { priceTerms && (
                     <Form.Group className='mb-10'>
                        <Form.Check
                           name="accept_price"
                           ref={register({ required: true })}
                           label={`Acepto el costo del envío a domicilio: ${priceTerms}.`}
                        />
                        {errors.accept_price && <Form.Text className="text-warning">Este campo es requerido</Form.Text>}
                     </Form.Group>
                  )}
                  <Button
                     type="submit"
                     className="full-width py-10"
                     disabled={loading || !priceTerms}
                  >
                     {!loading ? "Iniciar solicitud de domicilio" : "Cargando..."}
                  </Button>
               </FormFooter>
            </Row>
         </Form>
         { newAddress && (
            <NewAddressModal
               visible 
               onClose={() => {
                  setNewAddress(false)
                  setValue('address_id', '')
               }}
               userId={userId}
               reload={() => setUserAddresses(null)}
            />
         )}
      </React.Fragment>
   )
}

const FormFooter = styled.div`
   max-width: 550px;
   margin: auto;
   padding: 20px 30px;
`;