import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import Login from './Login'
import Forgot from './Forgot'
import Logout from './Logout'
import Register from './Register'
import Reset from './Reset'
import Activate from './Activate'

export default function Auth() {
   return (
      <Switch>
         <Redirect exact from="/auth" to="/auth/login" />
         
         <Route exact path="/auth/login" component={Login} />
         <Route exact path="/auth/forgot" component={Forgot} />
         <Route exact path="/auth/logout" component={Logout} />
         <Route exact path="/auth/register" component={Register} />
         <Route exact path="/auth/reset/:token" render={props => <Reset {...props} />} />
         <Route exact path="/auth/activate/:token" render={props => <Activate {...props} />} />
         
         <Redirect to="/404" />
      </Switch>
   )
}