import React, { useEffect } from 'react'
import styled from 'styled-components'

import NewPickupForm from './partials/NewPickupForm'

export default function NewPickup({ scope, setPageTitle }) {
   
   useEffect(() => setPageTitle('Solicitud de Entrega'))

   return (
      <Container>
         <NewPickupForm setPageTitle={setPageTitle} />
      </Container>
   )
}

const Container = styled.div`
   max-width: 948px;
   margin: auto;
`;