import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import AuthLayout from '../../layouts/AuthLayout'
import { Spinner } from 'react-bootstrap'

import { activate } from './services'
import { message } from 'antd'

export default function Activate(props) {
   const token = props.match.params.token
   const [isCompleted, setIsCompleted] = useState(false)

   !isCompleted && activate(token)
      .then(() => {
         message.success('Ha activado exitosamente su cuenta.')
         setIsCompleted(true)
      })
      .catch(error => {
         message.error(`Algo salio mal. ${error.message}`, 10)
         setIsCompleted(true)
      })

   if (!token || isCompleted) return <Redirect to="/" />

   return (
      <AuthLayout>
         <div className="text-center mt-20">
            <Spinner animation="border" />
         </div>
      </AuthLayout>
   )
}