import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form"
import { Form, Button } from 'react-bootstrap'
import { message } from 'antd'

import { createTicket, getTicketTypes } from './services'
import { handleError } from '../../helpers'

export default function NewTicketForm({ userId, reload }) {
	const [types, setTypes] = useState(null)
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, errors, reset } = useForm();

	useEffect(() => {
		!types && getTicketTypes()
			.then(res => setTypes(res.data.data))
			.catch(error => handleError(error))
	}, [types])

	const onSubmit = values => {
      setLoading(true)
      values.user_id = userId
      values.state = 'open'
      createTicket(values)
         .then(response => {
				message.success('Se ha registrado su novedad. Estaremos en contacto.')
            reset()
            reload()
         })
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
   };

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<Form.Label>Motivo Principal</Form.Label>
					<Form.Control
						name="ticket_type_id"
						as="select"
						ref={register({ required: true })}
					>
						<option value="">::Eliga el motivo de su solicitud::</option>
						{ types && types.map(type => <option key={type.id} value={type.id}>{type.name}</option>) }
					</Form.Control>
					{ errors.subject && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group>
					<Form.Label>Mensaje</Form.Label>
					<Form.Control 
						as="textarea"
						name="message"
						rows={5}
						ref={register({ required: true })}
					/>
					{ errors.message && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<div className="mt-20 text-right">
					<Button disabled={loading} type="submit"> 
						{ !loading ? "Enviar Novedad" : "Cargando..." }
					</Button>
				</div>
			</Form>
		</React.Fragment>
	)
}