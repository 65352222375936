import asisfarmaApi from '../../services/asisfarmaApi'

export const getDeliveries = params => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get('/deliveries', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getDelivery = (id, params) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get(`/deliveries/${id}`, { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const storeDelivery = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/deliveries', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateDelivery = (id,data) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .put(`/deliveries/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getAddresses = params => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get('/addresses', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const storeAddress = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/addresses', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
   
)