import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
   background-color: #00469A;
   padding-top: 70px;
`;

const Title = styled.h1`
   padding: 17px 30px;
   font-size: 21px;
   text-align: center;
   color: #fff;
   @media(min-width: 568px){
      text-align: left;
      max-width: 1200px;
      margin: auto;
   }
`;

export default function PageTitle({ title }) {
   return (
      <Container>
         <Title>{title}</Title>
      </Container>
   )
}