import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import { getUsers } from '../services'
import { handleError } from '../../../helpers'

export default function RelatedUsersSelect({ selectedUserId, setSelectedUserId, reload = null }) {
	const { user } = useSelector(state => state.auth)
	const [relatedUsers, setRelatedUsers] = useState(null)

	useEffect(() => {
		!relatedUsers && getUsers({
			'filter[parent_id]': user.id
		})
			.then(res => setRelatedUsers(res.data.data))
			.catch(error => handleError(error))
	}, [ relatedUsers, user ])

   if(!relatedUsers) return null

	return (
		<React.Fragment>
			<Form.Control 
            as="select"
            value={selectedUserId}
            onChange={e => {
               setSelectedUserId(e.target.value)
               if(reload) reload()
            }}            
            className="mb-10"
         >
            <option value={user.id}>{user.name}</option>
            { relatedUsers.map(relatedUser => (
               <option key={relatedUser.id} value={relatedUser.id}>
                  { relatedUser.name }{" "}({ relatedUser.document })
               </option>
            )) }
         </Form.Control>         
		</React.Fragment>
	)
}