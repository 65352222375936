import React, {useState} from 'react'

import AppLayout from '../../layouts/AppLayout'
import { Switch, Redirect, Route } from 'react-router-dom'

import NewDelivery from './NewDelivery'
import DeliveriesList from './DeliveriesList'
import SuccessConfirmation from './SuccessConfirmation'
import AttachmentsList from '../Attachments/AttachmentsList'

export default function Deliveries() {
   const [pageTitle, setPageTitle] = useState('Entregas & Domicilios')

   return (
      <AppLayout title={pageTitle}>
         <Switch>
            <Route exact path="/deliveries" render={() => <DeliveriesList setPageTitle={setPageTitle} />} />
            <Route path="/deliveries/new" render={() => (<NewDelivery setPageTitle={setPageTitle} />)} />

            <Route path="/deliveries/:id/attachments" render={props => (
               <AttachmentsList 
                  deliveryId={props.match.params.id} 
                  scope="deliveries" 
                  setPageTitle={setPageTitle} 
               /> 
            )} />

            <Route exact path="/deliveries/confirmation" render={() => (
               <SuccessConfirmation scope="deliveries" setPageTitle={setPageTitle} />
            )} />
            
            <Redirect to="/404" />
         </Switch>
         
      </AppLayout>
   )
}