import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default function UserMenu() {
   const [showMenu, setShowMenu] = useState(false)
   const name = useSelector(state => state.auth.user.name)

   const handleShowMenu = () => {
      setShowMenu(!showMenu)
   }

   return (
      <React.Fragment>
         <IconContainer onClick={handleShowMenu}>
            <FontAwesomeIcon icon={faUser} />
         </IconContainer>
         <BkgOverlay onClick={handleShowMenu} show={showMenu}>
            <DropdownContainer show={showMenu}>
               <MenuIntroDiv>
                  Hola {name.replace(/ .*/,'')}
               </MenuIntroDiv>
               <Link to="/auth/logout">
                  <MenuMegaLink>Cerrar Sesión</MenuMegaLink>
               </Link>
            </DropdownContainer>
         </BkgOverlay>
      </React.Fragment>
   )   
}

const IconContainer = styled.div`
   position: absolute;
   right: 15px;
   top: 0px;
   color: #fff;
   padding-top: 12px;
   padding-right: 15px;
   padding-left: 20px;
   height: 70px;
   float: right;
   font-size: 31px;   
   &:hover {
      opacity: 0.8;
      cursor: pointer;
   }
`;

const BkgOverlay = styled.div`
   display: ${props => !props.show && "none"};
   position: fixed;
   top: 70px;
   left: 0px;
   z-index: 100;
   width: 100%;
   height: 100%;
   background-color: #00000050
`;

const DropdownContainer = styled.div`
   display: ${props => !props.show && "none"};
   transition: visibility 0s, opacity 0.5s linear;   
   z-index: 10;
   background-color: #FFF;   
   position: fixed;
   right: 0px;
   top: 70px;
   text-align: left;
   border: none;
   width: 300px;   
   border-bottom-left-radius: 25px;
`;

const MenuIntroDiv = styled.div`
   background-color: #E6F0FF;
   color: #020252;
   padding: 30px 20px 30px;
   font-weight: bolder;
   font-size: 1.2rem;
`;

const MenuMegaLink = styled.div`
   width: 100%;
   padding: 25px 20px;   
   color: #0000aa;
   &:hover {
      cursor: pointer;        
   }
   @media(max-width: 386px) {
      width: 100%;
   }
`;