import React, { useState } from 'react'
import { Redirect, Link } from 'react-router-dom'
import AuthLayout from '../../layouts/AuthLayout'
import { Form, Button } from 'react-bootstrap'
import { useForm } from "react-hook-form";

import { resetPing, reset } from './services'
import { message } from 'antd'
import { handleError } from '../../helpers';

export default function Reset(props) {
   const token = props.match.params.token
   const [loading, setLoading] = useState(false)
   const [isValid, setIsValid] = useState(null)
   const [isCompleted, setIsCompleted] = useState(false)
   const { handleSubmit, register, errors } = useForm();

   !isValid && resetPing(token)
      .then(response => {
         setIsValid(true)
      })
      .catch(error => handleError(error))

   const onSubmit = values => {
      setLoading(true)
      !isCompleted && reset({ ...values, token })
         .then(() => {
            message.success('Ok. Su contraseña ha sido cambiada exitosamente.')
            setIsCompleted(true)
         })
         .catch(error => {
            handleError(error)
            setLoading(false)
         })
   };

   if (!token || isValid === false || isCompleted) return <Redirect to="/auth/login" />

   return (
      <AuthLayout>
         <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
               <Form.Label className="bold fs-17 text-secondary">Contraseña</Form.Label>
               <Form.Control
                  name="password"
                  type="password"
                  placeholder="Escriba una contraseña"
                  ref={register({ required: true, minLength: 8 })}
               />
               {errors.password && <Form.Text className="text-warning">Longitud mínima de 8 caracteres que incluyan por lo menos una letra mayúscula, una minúscula, un número y un símbolo o carácter especial.</Form.Text>}
            </Form.Group>
            <Form.Group>
               <Form.Label className="bold fs-17 text-secondary">Confirmar Contraseña</Form.Label>
               <Form.Control
                  name="password_confirmation"
                  type="password"
                  placeholder="Confirme de nuevo la contraseña"
                  ref={register({ required: true })}
               />
               {errors.password_confirmation && <Form.Text className="text-warning">Este campo es requerido.</Form.Text>}
            </Form.Group>
            <Button type="submit" disabled={loading} className="full-width mb-30 py-10">
               {!loading ? 'Cambiar Contraseña' : 'Cargando...'}
            </Button>
            <Link to="/auth/login" className="text-underline">
               Ir a inicio de sesión
               </Link>
         </Form>
      </AuthLayout>
   )
}