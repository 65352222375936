import React, {useState} from 'react'

import AppLayout from '../../layouts/AppLayout'
import { Switch, Redirect, Route } from 'react-router-dom'

import PickupsList from './PickupsList'
import NewPickup from './NewPickup'

import SuccessConfirmation from '../Deliveries/SuccessConfirmation'
import AttachmentsList from '../Attachments/AttachmentsList'

export default function Pickups() {
   const [pageTitle, setPageTitle] = useState('Entregas & Domicilios')

   return (
      <AppLayout title={pageTitle}>
         <Switch>
            <Route exact path="/pickups" render={() => <PickupsList setPageTitle={setPageTitle} />} />

            <Route path="/pickups/new" render={() => <NewPickup setPageTitle={setPageTitle} />} />

            <Route path="/pickups/:id/attachments" render={props => (
               <AttachmentsList 
                  scope="pickups" 
                  deliveryId={props.match.params.id} 
                  setPageTitle={setPageTitle}
               /> 
            )} />

            <Route exact path="/pickups/confirmation" render={() => (
               <SuccessConfirmation scope="pickups" setPageTitle={setPageTitle} />
            )} />
            
            <Redirect to="/404" />
         </Switch>
         
      </AppLayout>
   )
}