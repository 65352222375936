import React from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Button, Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { message } from 'antd'

import { storeUser } from '../services'
import { handleError } from '../../../helpers'

export default function NewUserModal({ visible, onClose, reload }) {
	const { handleSubmit, register, errors } = useForm()
	const { id } = useSelector(state => state.auth.user)

	const onSubmit = values => {
		storeUser({ ...values, role:'client', parent_id: id })
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Agregar Beneficiario</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label>Nombre Completo <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								name="name"
								ref={register({ required: true })}
							/>
							{ errors.name && <Form.Text className="text-warning">Este campos es requerido.</Form.Text>}
						</Form.Group>
						<Form.Group>
							<Form.Label>Documento de Identidad <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								name="document"
								ref={register({ required: true })}
							/>
							{ errors.document && <Form.Text className="text-warning">Este campos es requerido.</Form.Text>}
						</Form.Group>
						<Form.Group>
							<Form.Label>Correo Electrónico <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								type="email"
								name="email"
								ref={register({ required: true })}
							/>
							{ errors.email && <Form.Text className="text-warning">Este campos es requerido.</Form.Text>}
						</Form.Group>
						<Form.Group>
							<Form.Label>Celular de Contacto <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								type="number"
								name="mobile"
								ref={register({ required: true })}
							/>
							{ errors.mobile && <Form.Text className="text-warning">Este campos es requerido.</Form.Text>}
						</Form.Group>
						<Form.Group>
							<Form.Label>Parentesco <span className='text-danger'></span></Form.Label>
							<Form.Control
								as="select"
								name="parent_relationship"
								ref={register({ required: true })}
							>
								<option value="">:: Seleccione una opción ::</option>
								<option>Padre o Madre</option>
								<option>Hijo o Hija</option>
								<option>Abuelo o Abuela</option>
								<option>Familiar en segundo grado</option>
							</Form.Control>
							{ errors.parent_relationship && <Form.Text className="text-warning">Este campos es requerido.</Form.Text>}
						</Form.Group>
						<Button type="submit" color="primary">Agregar Beneficiario</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}