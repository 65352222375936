import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
   background: rgba(255,255,255,0.38);
   border: 4px dashed #FFFFFF;   
   border-radius: 15px;
   padding: 30px;
   text-align: center;
   width: 300px;
   margin: auto;
`;

export default function Empty({ title, description }) {
   return (
      <Container>
         <img src="/images/icons/alert-blue.png" width="60px" alt="" className="mb-20" />
         <h4 className="text-secondary bold">{title}</h4>
         <p>{description}</p>
      </Container>
   )
}
