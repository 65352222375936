import asisfarmaApi from '../../services/asisfarmaApi'

export const getUser = (id, params) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get(`/users/${id}`, { params })
            .then(response => resolve(response))
            .then(error => reject(error))
   })
)

export const updateUser = (id, data) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .put(`/users/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteAddress = (id) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .delete(`/addresses/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)