import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Spinner } from 'react-bootstrap'
import { Divider, List, message } from 'antd'

import NewAttachmentForm from './partials/NewAttachmentForm'
import Empty from '../../components/Empty'

import { deleteAttachment, getAttachments } from './services'
import { handleError } from '../../helpers'

export default function AttachmentsList({ scope, deliveryId, setPageTitle }) {
	const [attachments, setAttachments] = useState(null)
	const history = useHistory()

	useEffect(() => setPageTitle('Archivos Adjuntos'), [ setPageTitle ])

	useEffect(() => {
		!attachments && getAttachments({
			'filter[delivery_id]': deliveryId
		})
			.then(res => setAttachments(res.data.data))
			.catch(handleError)
	}, [ attachments, deliveryId ])

	if(!attachments) return <Spinner animation='border' />

	const handleNextStep = () => {
		if(attachments.length === 0)
			return message.warning('Debe adjuntar al menos una foto o archivo de su fórmula médica')

		history.push(`/${scope}/confirmation`)
	}

	const handleDelete = id => {
		deleteAttachment(id)
			.then(res => {
				message.info(res.data.message)
				setAttachments(null)
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<div className="text-center mb-15">
				<NewAttachmentForm 
					deliveryId={deliveryId} 
					reload={() => setAttachments(null)}
				/>
			</div>
			<hr />
			{ attachments.length > 0 ? (
				<div className='card'>
					<List 
						dataSource={attachments}
						rowKey={r => r.id}
						bordered
						renderItem={(item) => (
							<List.Item>
								<p className='mb-0'>Adjunto #{item.id}</p>
								<div>
									<a href={item.url} target="_blank" rel="noopener noreferrer">
										<Button variant='outline-primary' size="sm">
											Abrir adjunto
										</Button>
									</a>
									<Divider type="vertical" />
									<Button
										variant='outline-danger'
										size="sm"
										onClick={() => handleDelete(item.id)}
									>
										Eliminar
									</Button>
								</div>
							</List.Item>
						)}
					/>
				</div>
			) : (
				<Empty description="Adjunte una o varies imágenes o archivos de su formula médica y Mipres (si aplica)" />
			)}			
			<hr />
			<div className="text-center mt-30">
				<Button
					variant="primary" 
					onClick={handleNextStep}
				>
					Finalizar Solicitud de Entrega
				</Button>
			</div>
		</React.Fragment>
	)
}