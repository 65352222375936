import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from "react-hook-form";
import { Form, Button } from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom'

import AuthLayout from '../../layouts/AuthLayout'

import { login } from './services'
import { userLogin } from './actions'
import { handleError } from '../../helpers';

export default function Login() {
   const dispatch = useDispatch()
   const { handleSubmit, register, errors } = useForm()
   const [loading, setLoading] = useState(false)
   const authToken = useSelector(state => state.auth.token)

   if (authToken) return <Redirect to="/" />

   const onSubmit = values => {
      setLoading(true)
      !authToken && login(values)
         .then(response => (
            !authToken && dispatch(userLogin(response.data))
         ))
         .catch(error => {
            handleError(error)
            setLoading(false)
         })
   };

   return (
      <AuthLayout>
         <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
               <Form.Label className="bold fs-17 text-secondary">Cédula de Ciudadanía</Form.Label>
               <Form.Control
                  name="document"
                  placeholder="Escriba su cédula aquí"
                  ref={register({ required: true })}
               />
               {errors.document && <Form.Text className="text-warning">Este campo es requerido.</Form.Text>}
            </Form.Group>
            <Form.Group>
               <Form.Label className="bold fs-17 text-secondary">Contraseña</Form.Label>
               <Form.Control
                  name="password"
                  type="password"
                  placeholder="Escriba su contraseña aquí"
                  ref={register({ required: true })}
               />
               {errors.password && <Form.Text className="text-warning">Este campo es requerido.</Form.Text>}
            </Form.Group>
            <div className="text-right">
               <Link to="/auth/forgot" className="text-underline">
                  ¿Olvidó su contraseña?
               </Link>
            </div>
            <Button type="submit" disabled={loading} className="full-width mt-30 py-10">
               {!loading ? 'Iniciar Sesión' : 'Cargando autorizaciones...'}
            </Button>
            <div className="text-center mt-30 text-underline">
               <Link to="/auth/register">
                  ¿Aún no tienes cuenta?
               </Link>
            </div>
         </Form>
      </AuthLayout>
   )
}