import React, {useState} from 'react'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import moment from 'moment'

import TopBar from './partials/TopBar'
import PageTitle from './partials/PageTitle'
import SideMenu from './partials/SideMenu'

export default function AppLayout({ title, children }) {
   const [showMenu, setShowMenu] = useState(false)

   return (
      <Container>
         <TopBar handleShowSideMenu={()=>setShowMenu(!showMenu)} />
         <PageTitle title={title} />
         <ContentContainer>
            {children}
         </ContentContainer>
         <Footer>
            <Row className="mb-20 ml-0 mr-0">
               <Col className="text-right" style={{ paddingTop: '1px' }}>
                  <a href="https://apps.apple.com/us/app/asisfarma/id1547879434" target="_blank" rel="noopener noreferrer">
                     <img src="/images/download_appstore.png" alt="Descargar App Store" width="125px" />
                  </a>
               </Col>
               <Col className="text-left">
                  <a href="https://play.google.com/store/apps/details?id=com.instafarma.asisfarma" target="_blank" rel="noopener noreferrer">
                     <img src="/images/download_playstore.png" alt="Descargar Play Store" width="150px" />
                  </a>
               </Col>
            </Row>
            Asisfarma ©{moment().format('YYYY')} - Una dosis de vida - Versión {process.env.REACT_APP_VERSION}
         </Footer>
         <SideMenu handleShowMenu={()=>setShowMenu(!showMenu)} showMenu={showMenu} />
      </Container>
   )
}

const Container = styled.div``;

const ContentContainer = styled.div`
   max-width: 1200px;
   margin: auto;
   padding: 25px;
`;

const Footer = styled.div`
   text-align: center;
   color: #888;
   margin: 25px auto;
   padding-bottom: 30px;
`;