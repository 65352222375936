import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createStore } from 'redux';

import reducers from './reducers'

const persistConfig = {
   key: 'auth',
   storage: storage,
   whitelist: ['auth']
 };
const pReducer = persistReducer(persistConfig, reducers);

const store = createStore(
   pReducer,
   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const persistor = persistStore(store);
export { persistor, store };