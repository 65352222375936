import asisfarmaApi from '../../services/asisfarmaApi'

export const getTickets = params => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get('/tickets', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createTicket = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/tickets', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getTicketTypes = params => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get('/ticket_types', { params })
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)