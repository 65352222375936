import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap';

import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import UserMenu from './UserMenu';

export default function TopBar({ handleShowSideMenu }) {
   return (
      <Container>
         <Content>
            <Row>
               <Col>
                  <MenuTrigger onClick={handleShowSideMenu}>
                     <FontAwesomeIcon icon={faBars} />
                  </MenuTrigger>
               </Col>
               <Col>
               </Col>
               <Col className="text-right">
                  <UserMenu />
               </Col>
            </Row>
         </Content>
      </Container>
   )
}

const Container = styled.div`
   background-color: #0b0b84;
   height: 70px;
   z-index: 150;
   position: fixed;
   width: 100%;   
`;

const Content = styled.div`
   max-width: 1200px;
   margin: auto;
`;

const MenuTrigger = styled.div`
   color: #fff;
   font-size: 35px;
   margin-top: 12px;
   margin-left: 15px;
   &:hover {
      opacity: 0.8;      
      cursor: pointer;
   }
`;